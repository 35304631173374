import { get, post, put } from "./base-api";


// profile add
export const profileAdd = (body = {}) => {
    return post(`/profileCreate`, body, 'multipart/form-data');
};

// single profile
export const singleProfile = () => {
    return get(`/profileSingle`);
};

// update profile
export const updateProfile = (id: string, body = {}) => {
    return put(`/updateProfile/${id}`, body, 'multipart/form-data');
};

// customization add
export const customizationAdd = (body = {}) => {
    return post(`/customCreate`, body, 'multipart/form-data');
};
// stripe Create
export const stripeCreate = (body = {}) => {
    return post(`/stripeCreate`, body);
};
// stripe info
export const getStripe = async () => {
    return get(`/getStripe`);
};
// stripe update
export const stripeUpdate = async (id:string, body = {}) => {
    return put(`/stripeUpdate/${id}`, body);
};
