import React, { useState, useEffect } from 'react';
import "./Visitor.css";
import qrImage from "../../Assets/Images/icon/wifiScan1.svg";
import { DESKIE_API as API } from '../../config';
import logo from "../../Assets/Images/logo/logo.svg";
import { Dropdown } from 'react-bootstrap';
import arrow from "../../Assets/Images/icon/downIcon.svg";
import { v4 as uuidv4 } from 'uuid';
import { visitorAdd } from '../../api/visitor';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import checkIcon from "../../Assets/Images/icon/check-circle1.svg"
import { getMemberList } from '../../api/member';
import { adminList } from '../../api/admin';
import { singleProfile } from '../../api/settings';
import { log } from 'console';

const Visitor = () => {
  const [profile, setProfile] = useState<any>();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedItem, setSelectedItem] = useState<string | null>(null);
  const [roleItem, setRoleItem] = useState<string | null>(null);
  const [roleId, setRoleId] = useState<string | null>(null);
  const [visitorOption, setVisitorOption] = useState(true);
  const [visitorInfo, setVisitorInfo] = useState(false);
  const [successCheck, setSuccessCheck] = useState(false);
  const [member, setMember] = useState([]);
  const [admin, setAdmin] = useState([]);
  const [reasonOpen, setReasonOpen] = useState(false);
  const [memberOpen, setMemberOpen] = useState(false);

  const handleReasonToggle = (isOpen: any) => {
    setReasonOpen(isOpen);
  };

  const handleMemberToggle = (isOpen: any) => {
    setMemberOpen(isOpen);
  };

  const getSingleProfile = async () => {
    try {
      const data = await singleProfile();
      console.log(data.data);
      setProfile(data.data);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {

    getSingleProfile();

    getMemberList(100, 1).then((data) => {
      setMember(data.members);
    }).catch((err) => { console.log(err) });

    adminList().then((data) => {
      setAdmin(data);
    }).catch((err) => { console.log(err) });
  }, []);



  const handleSelect = (eventKey: string | null) => {
    setSelectedItem(eventKey);
  };

  const handleRoleSelect = (eventKey: string | null) => {
    const [id, name]: any = eventKey?.split('|');
    setRoleItem(name);
    setRoleId(id);
  };

  const manuallyOption = () => {
    setVisitorOption(false);
    setVisitorInfo(true);
  }

  const saveVisitor = () => {
    let invoiceInfo = {
      "id": uuidv4(),
      "name": name,
      "email": email,
      "reason": selectedItem,
      "looking": roleId,
      "companyName": profile.company_name,
      "address": profile.address,
      "logoImage": `${API}/${encodeURI(profile.company_logo_dark)}`,
    }

    visitorAdd(invoiceInfo).then((data) => {
      showNotifications(TOAST_TYPE.success, data.message);
      setVisitorInfo(false);
      setSuccessCheck(true);
    }).catch(() => {
      showNotifications(TOAST_TYPE.error, "Insert all the information");
    })
  }


  return (
    <section className='visitorSection'>
      <div className="visitorLeft">
        <div className='visitorImage' style={{ background: `linear-gradient(rgba(31, 41, 55, 0.9), rgba(31, 41, 55, 0.94)), url(${profile && `${API}/${encodeURI(profile.background)}`})` }}>
          <div>
            {profile && profile.company_logo_dark ?
              <img src={`${API}/${profile.company_logo_dark}`} alt="logo" style={{ maxWidth: '280px', maxHeight: '75px' }} />
              : <img src={logo} alt="logo" />
            }
            <h5 className='mt-3'>Visitor Check-in</h5>
          </div>

        </div>
      </div>
      {visitorOption ? <div className="visitorRight">
        <h5 className='mb-4'>Scan QR code to check-in as a guest</h5>
        <img src={qrImage} alt="qrImage" />
        <div className="orLine">
          <h2><span>or</span></h2>
        </div>
        <button className='tapNext' onClick={manuallyOption}>Tap to Check in Manually</button>
      </div> : ""}
      {visitorInfo ? <div className="visitorRight">
        <div className="reasonVisit memberInput">
          <label className='mt-4'>Name</label>
          <input type="text" onChange={(e) => setName(e.target.value)} placeholder='Enter your name' className='form-control' required />
        </div>
        <div className="reasonVisit memberInput">
          <label className='mt-4'>Email</label>
          <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder='Enter your E-mail' className='form-control' required />
        </div>
        <div className="reasonVisit memberInput">
          <label className='mt-4'>Reason For Visit</label>
          <Dropdown onToggle={handleReasonToggle} onSelect={handleSelect} className='dropdown-menu-margin visitor-dropdown' >
            <Dropdown.Toggle id="dropdown-basic" className={reasonOpen ? 'visitor-dropdown' : ''} >
              {selectedItem && selectedItem ? <>{selectedItem} <img src={arrow} alt="arrow" /></> : <div className='noneSelect d-flex w-100'>Select a reason <img src={arrow} alt="arrow" /></div>}
            </Dropdown.Toggle>

            <Dropdown.Menu className='visitor-dropdown-edge'>
              <div style={{ maxHeight: '230px', overflowY: 'auto' }}>
                <Dropdown.Item className='dropDown-focus' eventKey="Guest">Guest</Dropdown.Item>
                <Dropdown.Item className='dropDown-focus' eventKey="Delivery">Delivery</Dropdown.Item>
                <Dropdown.Item className='dropDown-focus' eventKey="Maintenance">Maintenance</Dropdown.Item>
                <Dropdown.Item className='dropDown-focus' eventKey="Tour">Tour</Dropdown.Item>
                <Dropdown.Item className='dropDown-focus' eventKey="Other">Other</Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="reasonVisit memberInput mb-2">
          <label className='mt-4'>I’m Here To See</label>
          <Dropdown onToggle={handleMemberToggle} onSelect={handleRoleSelect} className='bg-white'>
            <Dropdown.Toggle id="dropdown-basic" className={memberOpen ? 'visitor-dropdown' : ''}>
              {roleItem && roleItem ? <>{roleItem} <img src={arrow} alt="arrow" /></> : <div className='noneSelect d-flex w-100'>Select a member <img src={arrow} alt="arrow" /></div>}
            </Dropdown.Toggle>

            <Dropdown.Menu className='visitor-dropdown-edge'>
              <div style={{ maxHeight: '120px', overflowY: 'auto' }}>
                {admin && admin.map((admin: any, index: number) => <Dropdown.Item className='dropDown-focus' eventKey={`${admin.id}|${admin.first_name} ${admin.last_name}`}>{admin.first_name} {admin.last_name}</Dropdown.Item>)}
                {member && member.map((member: any, index: number) => <Dropdown.Item className='dropDown-focus' eventKey={`${member.id}|${member.first_name} ${member.last_name}`}>{member.first_name} {member.last_name}</Dropdown.Item>)}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <button className='tapNext mt-4' onClick={saveVisitor}>Submit</button>
      </div> : ""}

      {successCheck ? <div className="visitorRight">
        <div className="success text-center">
          <img className='mb-4' src={checkIcon} alt="check" />
          <h5>Welcome, {name}!</h5>
          <h4>{roleItem} has been notified that you’re here.</h4>
        </div>
      </div> : ""}

    </section>
  )
}

export default Visitor