import React, { Component, ErrorInfo, ReactNode } from 'react';
import { Button, Row } from 'react-bootstrap';
import { Link, Location } from 'react-router-dom';
import "./ErrorBoundary.css";

interface ErrorBoundaryProps {
  children: ReactNode;
  location: Location
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Update state to render the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can log the error to an error reporting service
    console.error("Error caught by Error Boundary:", error, errorInfo);
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.location !== prevProps.location) {
      // Reset the error state
      this.setState({ hasError: false });
    }
  }
  

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <Row className='text-center'>
            <h2 className="mb-4">Looks like an error occured.</h2>
            <Link to="/">
              <Button>Go Home</Button>
            </Link>
          </Row>
        </div>
      )
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
