import React, { useEffect, useState } from 'react'
import Layout from '../../Component/Layout/Layout'
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import './Calender.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import avatar from '../../Assets/Images/icon/Avatar.png'
import { faCircle, faCircleDot } from '@fortawesome/free-regular-svg-icons'
import MonthView from './MonthView'
import { getTaskList } from '../../api/task'
import moment from 'moment'
import { DESKIE_API as API } from '../../config'
import memberBlank from '../../Assets/Images/icon/memberAvatar.svg'
import { getTourList } from '../../api/tour'
import { bookList, resourceList } from '../../api/resource'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import { Link } from 'react-router-dom'

interface Task {
  title: string
  filter: string
  date: string
  classNames: string
}
const Calender = () => {
  const [taskList, setTaskList] = useState<Task[]>([])
  const [upcomingTasks, setUpcomingTasks] = useState<any[]>([]);
  console.log('upcomingTasks',upcomingTasks);
  
  const [selectedFilters, setSelectedFilters] = useState<string[]>([])

  const handleFilterChange = (filter: string) => {
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(filter)
        ? prevFilters.filter((f) => f !== filter)
        : [...prevFilters, filter]
    )
  }

  const filteredTasks =
    selectedFilters.length > 0
      ? taskList.filter((task) => selectedFilters.includes(task.filter))
      : taskList

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [taskData, tourData, resourceData] = await Promise.all([
          getTaskList('ALL', 'ASC'),
          getTourList(100, 1, ''),
          bookList(),
        ])

        // Combine all data into one array and normalize the date field
        const combinedData = [
          ...taskData.map((item: any) => ({
            ...item,
            due_date: item.dueDate,
            type: 'Task',
          })),
          ...tourData.tour.map((item: any) => ({
            ...item,
            due_date: item.tour_date,
            title: item.name,
            type: 'Tour',
          })),
          ...resourceData.map((item: any) => ({
            ...item,
            due_date: item.book_date,
            title: item.resource_name,
            type: 'Resource',
          })),
        ]
        // console.log('combinedData', combinedData);

        // Get the current date in YYYY-MM-DD format
        const currentDate = new Date().toISOString().split('T')[0]

        // Filter and sort the combined data based on the due date
        const upcoming = combinedData.filter(
          (item: any) => item.due_date >= currentDate
        )
        const sortedUpcoming = upcoming
          .sort(
            (a, b) =>
              new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
          )
          .slice(0, 6)
        setUpcomingTasks(sortedUpcoming)
      } catch (err){
        console.log(err);
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const taskData = await getTaskList('ALL', 'ASC')
        const tourData = await getTourList(100, 1, '')
        const resourceData = await bookList()

        const transformedTasks = taskData.map((task: any) => {
          let title = task.title
          if (title.length > 9) {
            title = title.slice(0, 9) + '...'
          }
          return {
            title: title,
            filter: 'task',
            classNames: ['task-background'],
            date: new Date(task.dueDate).toISOString().split('T')[0],
          }
        })

        const transformedTours = tourData.tour.map((tour: any) => {
          let title = tour.name
          if (title.length > 9) {
            title = title.slice(0, 9) + '...'
          }
          return {
            title: title,
            filter: 'tour',
            classNames: ['tour-background'],
            date: tour.tour_date
              ? new Date(tour.tour_date).toISOString().split('T')[0]
              : tour.tour_date,
          }
        })

        const transformedResource = resourceData.map((resource: any) => {
          let title = resource.resource_name
          if (title.length > 9) {
            title = title.slice(0, 9) + '...'
          }
          return {
            title: title,
            filter: 'resource',
            classNames: ['resource-background'],
            date: new Date(resource.book_date).toISOString().split('T')[0],
          }
        })

        const mergedList = [
          ...transformedTasks,
          ...transformedTours,
          ...transformedResource,
        ]

        setTaskList(mergedList)
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }

    fetchData()
  }, [])

  return (
    <>
      <Layout>
        <div className='mainContent' id='calendar'>
          <div className='calendar'>
            <div className='eventList'>
              <div className='p-4 calenderList'>
                <div className='eventHeading pb-3'>
                  <p>Upcoming Events</p>
                  <button>See All</button>
                </div>
               <div className='eventSide'>
               {upcomingTasks &&
                  upcomingTasks.map((event: any, i: number) => (
                    <div key={i} className='eventDetails bg-white'>
                      {event.task_image ? (
                        <img src={`${API}/${event.task_image}`} alt='event' />
                      ) : (
                        <img
                          className='default'
                          src={memberBlank}
                          alt='event'
                        />
                      )}
                      <div className='eventInfo w-100'>
                        <div className='eventCategory justify-content-between'>
                          <p>{event.title}</p>
                          <Link to={event.type === 'Task' ? '/task' : event.type === 'Tour' ? '/tours' : '/resources'}
                            className={
                              event.type === 'Task'
                                ? 'task'
                                : event.type === 'Tour'
                                ? 'tour'
                                : 'resource'
                            }
                          >
                            {event.type}
                          </Link>
                        </div>
                        <div className='eventCategory'>
                          <span>
                            {moment(event.due_date).format('MMM D, YYYY')} {event.tour_time ? ` - ${event.tour_time}` : ""} {event.start_time ? ` - ${event.start_time}, ${event.end_time}` : ""}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
               </div>
              </div>
              <div className='eventUpcoming pb-0'>
                <div className='eventFilters my-0'>
                  <h1>Filters</h1>
                  <div className='filterCheck mt-3'>
                    <ul className='mb-0'>
                      <li>
                        <div>
                          <span className='filterColor filterBlack'></span>{' '}
                          <p>Bookings</p>
                        </div>
                        <label className='tableCheckBox filterCheckBox'>
                          <div className='contactCheck'>
                            <input
                              type='checkbox'
                              checked={selectedFilters.includes('resource')}
                              onChange={() => handleFilterChange('resource')}
                            />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </li>
                      <li>
                        <div>
                          <span className='filterColor filterBlue'></span>{' '}
                          <p>Tasks</p>
                        </div>
                        <label className='tableCheckBox filterCheckBox'>
                          <div className='contactCheck'>
                            <input
                              type='checkbox'
                              checked={selectedFilters.includes('task')}
                              onChange={() => handleFilterChange('task')}
                            />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </li>
                      <li>
                        <div>
                          <span className='filterColor filterGreen'></span>{' '}
                          <p>Tours</p>
                        </div>
                        <label className='tableCheckBox filterCheckBox'>
                          <div className='contactCheck'>
                            <input
                              type='checkbox'
                              checked={selectedFilters.includes('tour')}
                              onChange={() => handleFilterChange('tour')}
                            />
                            <span className='checkmark'></span>
                          </div>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='fullCalenderBox' style={{ width: '80%' }}>
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin]}
                initialView='dayGridMonth'
                weekends={true}
                headerToolbar={{
                  left: 'today',
                  center: 'prev,title,next',
                  right: 'dayGridDay,dayGridWeek,dayGridMonth',
                }}
                events={filteredTasks}
              />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Calender
