import { getMiddleWord } from '../CommonFunction/Function';
import http from '../utils/http';
import { DESKIE_API as API } from '../config';


export const post = (
    url: string,
    body: any = {},
    contentType: string = 'application/json'
) => {
    return http(contentType).post(url, body);
};

// GET request
export const get = (url: string) => {
    return http().get(url)
};

// PUT request
export const put = (url: string, body: any = {}, contentType = 'application/json') => {
    return http(contentType).put(url, body);
}

// PATCH request
export const patch = (url: string, body: any = {}, contentType = 'application/json') => { 
    return http(contentType).patch(url, body);
};

// DELETE request
export const del = (url: string, body: any = {}, contentType = 'application/json') => {
    return http(contentType).delete(url, body);
};

export const getJwt = (url: string, token: string) => {
    return fetch(`${API}${url}`, {
        method: 'GET',
        headers: {
            "x-database-name": getMiddleWord(window.location.hostname) || '',
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: (token) ? `Bearer ${token.toString()}` : "",
        }
    })
        .then((res) => {
            return res.json();
        })
        .catch((err) => {
            return err;
        });
};