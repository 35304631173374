
import { post } from "./base-api";

// member list
export const paymentProcess = (body={}) => {
    return post(`/paymentProcess`,body);
};


export const paymentHook = async (body={}) => {
    return post(`/paymentHook`,body);
};
