import { del, get, post } from "./base-api";
// task add
export const visitorAdd = (body = {}) => {
    return post(`/visitorCreate`,body);
};
export const getVisitorList = (limit: number, page: number, search: string) => {
    return get(`/visitorList?limit=${limit}&page=${page}&search=${search}`);
};
export const visitorDelete = (id: string) => {
    return del(`/visitorDelete/${id}`);
};

