import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import pen from "../../Assets/Images/icon/pen.svg";
import SignatureModal from './SignatureModal';
import { getAgreement } from '../../api/agreement';
import moment from 'moment';

interface tabMemberProps {
    tabChoose: (tab: string, select: string) => void;
    dataURL: string;
    setDataURL: (value: string) => void;
    signName: string;
    setSignName: (value: string) => void;
    dataFile: string;
    setDataFile: (value: any) => void;
    signatureAdd: () => void;
    memberId: string;
}

const Agreement = ({ tabChoose, signatureAdd,memberId, dataURL, setDataURL, signName, setSignName, dataFile, setDataFile }: tabMemberProps) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [content, setContent] = useState('')
    const deleteSign = () => {
        setDataFile("");
        setSignName("");
        setDataURL("");
    }

    const editSign = () => {
        setShow(true);
    }

    function handleButtonClick() {
        tabChoose("done", "agreement");
        signatureAdd();
    }

    useEffect(() => {
        getAgreement().then((data) => {
            if (data.data) {
                setContent(data.data.agreement);    
            }
        }).catch((err) => {console.log(err)});
      }, [])

    return (
        <>
            <div className="agreementInfo">
                <h1>Membership Agreement</h1>
                <div className='scroll-container my-3'>
                    <div className="agreementText my-0 px-3 border-0">
                        <div dangerouslySetInnerHTML={{ __html: content }} />
                    </div>
                </div>
                <div className="agreeCheck px-3 mb-3">
                    <label className="agreement">
                        <label className="tableCheckBox">
                            <div className="contactCheck">
                                <input type="checkbox" name="agreement" />
                                <span className="checkmark"></span></div>
                        </label>
                        <span className='agreeText'>I agree to these membership terms, {moment(new Date()).format("MMMM DD YYYY")}</span>
                    </label>
                </div>

                {dataFile ? <div className="signShowBox">
                    <div className="signShow">
                        {dataURL ? (
                            <img
                                className="sigImage"
                                src={dataURL}
                                alt="user generated signature"
                            />
                        ) : null}
                        <span className='signatureName'>{signName && signName}</span>
                    </div>
                    <div className="signChange">
                        <button onClick={editSign}>Edit</button>
                        <button onClick={deleteSign}>Delete</button>
                    </div>
                </div> : <div className="signBox" onClick={handleShow}>
                    <img src={pen} alt="pen" />
                    <p className='mt-2 mb-0'>Click here to sign</p>
                </div>}

                <div className="tabPanelBtn">
                    <button className='back' onClick={() => tabChoose("password", "agreement")}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    {signName.length && dataURL.length ? <button className='continue' onClick={handleButtonClick}>Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                        : <button className='disable'>Continue <FontAwesomeIcon icon={faArrowRight} /></button>
                    }

                </div>
            </div>
            <SignatureModal memberId={memberId} show={show} setShow={setShow} handleClose={handleClose} setDataURL={setDataURL} setDataFile={setDataFile} signName={signName} setSignName={setSignName} />
        </>
    )
}

export default Agreement