import { del, get, post, put } from "./base-api";


// task add
export const taskAdd = (body = {}) => {
    return post(`/taskCreate`, body, 'multipart/form-data');
};

// task list
export const getTaskList = (status: string,sort: string) => {
    return get(`/taskList/${status}?sort=${sort}`);
};
// task status update
export const updateStatus = (id: string, body = {}) => {
    return put(`/taskStatus/${id}`,body);
};
// task delete
export const deleteTask = (id: string) => {
    return del(`/taskDelete/${id}`);
};
// single task
export const getSingleTask = (id: string) => {
    return get(`/singleTask/${id}`);
};

export const taskUpdate = (id: string,body = {}) => {
    return put(`/taskUpdate/${id}`, body, 'multipart/form-data');
};

