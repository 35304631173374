import React from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';

interface ErrorBoundaryWrapperProps {
  children: React.ReactNode;
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({ children }) => {
  const location = useLocation();
  return (
    <ErrorBoundary location={location}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
