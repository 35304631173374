import React from 'react';
import finish from "../../Assets/Images/background/finish.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { loginAdmin } from '../../api/admin';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { authenticate } from '../../api/auth';
interface tabMemberProps {
    tabChoose: (tab: string, select: string) => void;
    password: any;
    email: any
}

const Finish = ({ tabChoose, email, password }: tabMemberProps) => {
    const navigate = useNavigate();
    function handleButtonClick() {
        tabChoose("done", "");
        loginAdmin({ email, password }).then((data) => {
            authenticate(data, () => {
                setTimeout(() => {
                    return navigate("/my-home");
                }, 500)
            })
        }).catch(() => {
            showNotifications(TOAST_TYPE.error, 'Wrong information');
        })
    }
    return (
        <>
            <div className="finishInfo">
                <div className="finishText">
                    <img src={finish} alt="finish" />
                    <h6>Congratulations, you’re done!</h6>
                    <p>Click “Finish” to login!</p>
                </div>
                <div className="tabPanelBtn">
                    <button className='back' onClick={() => tabChoose("agreement", "done")}><FontAwesomeIcon icon={faArrowLeft} /> Back</button>
                    <button className='continue' onClick={handleButtonClick}>Finish</button>
                </div>
            </div>
        </>
    )
}

export default Finish