import { get, post, put } from "./base-api";
export const addAssignment = async (body={}) => {
    return post(`/addAssignment`,body);
};
export const getAssignment = async (limit: number, page: number) => {
    return get(`/getAssignment?limit=${limit}&page=${page}`);
};
export const assignmentUpdate = async (body={}) => {
    return put(`/assignmentUpdate`,body);
};
