import { get, post, put } from "./base-api";

// admin login
export const postAdd = (body = {}) => {
    return post('/postCreate', body, 'multipart/form-data');
}

// post list
export const getPostList = (userId: string) => {
    return get(`/postList/${userId}`);
};
// comment
export const postComment = (body = {}) => {
    return post('/postComment', body, 'multipart/form-data');
};

export const getPostComment = (id: string) => {
    return get(`/getComment/${id}`);
};

export const replyComment = (body = {}) => {
    return post('/commentReply', body, 'multipart/form-data');
};

export const commentCommentReply = (body = {}) => {
    return post('/commentCommentReply', body, 'multipart/form-data');
};

// post likes
export const likesPost = (data = {}) => {
    return post(`/postLike`, data);
};
// post likes update
export const likesPostEdit = (data = {}) => {
    return put(`/postLikeUpdate`, data);
};

// comment likes
export const commentLike = (data = {}) => {
    return post(`/commentLike`, data);
};
// comment likes update
export const commentLikeUpdate = (data = {}) => {
    return put(`/commentLikeUpdate`, data);
};

// comment reply likes
export const commentReplyLike = (data = {}) => {
    return post(`/commentReplyLike`, data);
};
// comment reply likes update
export const commentReplyLikeUpdate = (data = {}) => {
    return put(`/commentReplyLikeUpdate`, data);
};

// post delete
export const deletePost = (id: string, body = {}) => {
    return put(`/deletePost/${id}`, body);
};