import { get, post, put } from "./base-api";


// admin login
export const spacesAdd = (body = {}) => {
    return post(`/spacesCreate`, body, 'multipart/form-data');
};
// spaces list
export const getSpacesList = (limit: number, page: number, search: string) => {
    return get(`/spacesList?limit=${limit}&page=${page}&search=${search}`);
};
// single spaces
export const singleSpaces = (id:string) => {
    return get(`/spacesSingle/${id}`);
};
// update spaces info
export const updateSpaces = (id:string,body = {}) => {
    return put(`/editSpaces/${id}`, body, 'multipart/form-data');
};

// update spaces member add
export const memberAddSpaces = (body = {}) => {
    return post(`/addMemberToSpace`,body);
};
// update spaces member add
export const bookedSpaces = (limit: number, page: number, search: string) => {
    return get(`/bookedSpaces?limit=${limit}&page=${page}&search=${search}`);
};
// update spaces member add
export const singleSpacesList = async (id: string, limit: number, page: number) => {
    return get(`/singleSpacesList?limit=${limit}&page=${page}&spaceId=${id}`);
};
