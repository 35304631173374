import { del, get, post, put } from "./base-api";

// admin login
export const filesAdd = (body = {}) => {
    return post('filesCreate', body, 'multipart/form-data');
};
// files list
export const getFilesList = (limit: number, page: number, filter: string, search: string) => {
    return get(`/filesList?limit=${limit}&page=${page}&filter=${filter}&search=${search}`);
};

// files delete
export const filesDelete = (id: string) => {
    return del(`/deleteFiles/${id}`);
};

// favorite update files
export const favoriteFile = (id:string) => {
    return put(`/fileFavorite/${id}`);
};

// update shares files
export const shareUpdate = (id:string,share={}) => {
    return put(`/fileShare/${id}`,share);
};

// favorite files list
export const getFavoriteList = () => {
    return get(`/favoriteList`);
};

// upload files for chat
export const uploadFilesForChat = (body = {}) => {
    return post('/fileUploadForChat', body, 'multipart/form-data');
}
