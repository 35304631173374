import React, { useState, useEffect } from 'react'
import { getStripe, stripeCreate, stripeUpdate } from '../../api/settings';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';
import { v4 as uuidv4 } from 'uuid';

interface FinanceProps {
    settingTab: (type: string) => void;
}

const Finance = ({ settingTab }: FinanceProps) => {
    const [secretId, setSecretId] = useState("");
    const [publishId, setPublishId] = useState("");
    const [stripeId, setStripeId] = useState("");
    const [stripeValue, setStripeValue] = useState(false);
    const stripeAdd = () => {
        let stripeInfo = {
            "id": uuidv4(),
            "secret_id": secretId,
            "publish_id": publishId
        }
        stripeCreate(stripeInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Stripe Update Successfully !!");
        }).catch((err) => {console.log(err)});
    }


    useEffect(() => {
        getStripe().then((data) => {
            setStripeId(data.data.id);
            setSecretId(data.data.secret_key);
            setPublishId(data.data.publish_key);
            setStripeValue(true)
        }).catch((err) => {console.log(err)});
    }, []);

    const updateStripe = () => {
        let stripeInfo = {
            "secret_id": secretId,
            "publish_id": publishId
        }
        stripeUpdate(stripeId, stripeInfo).then(() => {
            showNotifications(TOAST_TYPE.success, "Stripe Update Successfully !!");
        }).catch((err) => {console.log(err)});
    }


    return (
        <>
            <div className='mainContent'>
                <div className="settingPage">
                    <div className="companyOptions">
                        <button onClick={() => settingTab('account')}>Personal Profile</button>
                        <button onClick={() => settingTab('profile')}>Company Profile</button>
                        <button className='activeBtn' onClick={() => settingTab('finance')}>Finances</button>
                        {/* <button onClick={() => settingTab('users')}>Users</button> 
                        <button onClick={() => settingTab('custom')}>Customization</button> */}
                        <button onClick={() => settingTab('agreement')}>Onboarding</button>
                        <button onClick={() => settingTab('tours')}>Tours</button>
                    </div>
                    <div className="companyProfile">
                        <div className='profileHeading'>
                            <h6>Company Finance</h6>
                            <p>Update your company’s info here.</p>
                        </div>
                        <div className='profileSave'>
                            <button className='cancel'>Cancel</button>
                            {stripeValue ? <button className='save' onClick={updateStripe}>Save</button>
                                : <button className='save' onClick={stripeAdd}>Save</button>}
                        </div>
                    </div>
                    <div className="companyName">
                        <p>Stripe - Secret Key</p>
                        <div className='rightSideSetting col-8'>
                            <div className="memberInput">
                                <input type="password" value={secretId} onChange={(e) => setSecretId(e.target.value)} placeholder='Enter your Stripe Secret Key' className='form-control' required />
                            </div>
                        </div>
                    </div>
                    <div className="companyName publishKey">
                        <p>Stripe - Publishable Key</p>
                        <div className='rightSideSetting col-8'>
                            <div className="memberInput">
                                <input type="password" value={publishId} onChange={(e) => setPublishId(e.target.value)} placeholder='Enter your Stripe Publishable Key' className='form-control' required />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Finance