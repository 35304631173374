import { del, get, post } from "./base-api";
// task add
export const tourAdd = (body = {}) => {
    return post(`/tourCreate`,body);
};
export const getTourList = (limit: number, page: number, search: string) => {
    return get(`/tourList?limit=${limit}&page=${page}&search=${search}`);
};
export const tourTime = (body={}) => {
    return get(`/tourTime?dueDate=${body}`,);
};

export const tourDelete = (id: string) => {
    return del(`/tourDelete/${id}`);
};

