import { get, post, put } from "./base-api";


// task add
export const resourceAdd = (body = {}) => {
    return post(`/resourceCreate`, body, 'multipart/form-data');
};

// resource list
export const resourceList = (limit: number, page: number, sort: string) => {
    return get(`/resourceList?limit=${limit}&page=${page}&type=${sort}`);
};

// single resource
export const singleResource = (id: string) => {
    return get(`/singleResource/${id}`);
};
// resourceBooking
export const resourceBooking = (body={}) => {
    return post(`/resourceBooking`,body);
};

// admin resource list
export const adminResourceList = () => {
    return get(`/adminResourceList`);
};
// single resource
export const resourceBook = (id: string) => {
    return get(`/resourceBook/${id}`);
};

// resource update
export const resourceUpdate = (id: string,body = {}) => {
    return put(`/resourceUpdate/${id}`, body, 'multipart/form-data');
};

// resourceBooking
export const resourceInvoice = (body={}) => {
    return post(`/resourceInvoice`,body);
};
// resource Invoice List
export const resourceInvoiceList = () => {
    return get(`/resourceInvoiceList`);
};

// resource Book Time
export const resourceBookTime = (id:string) => {
    return get(`/resourceBookTime/${id}`);
};

// book List
export const bookList = () => {
    return get(`/bookList`);
};
