import React, { useState, useEffect } from 'react'
import { Dropdown, Table } from 'react-bootstrap';
import "./Billing.css";
import Layout from '../../Component/Layout/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import more from "../../Assets/Images/icon/dots-vertical.svg";
import arrowDown from "../../Assets/Images/icon/downIcon.svg";
import { DESKIE_API as API } from '../../config';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getInvoicesList, monthlyDate } from '../../api/invoice';
import { useNavigate } from 'react-router-dom';
import memberAvatar from "../../Assets/Images/icon/memberAvatar.svg";
import spaceAvatar from "../../Assets/Images/icon/spaceAvatar.png";
import Pagination from '../../Component/Pagination/Pagination';

const Billing = () => {
    const navigate = useNavigate();

    const [invoiceTag, setInvoiceTag] = useState("all");
    const [invoiceList, setInvoiceList] = useState<any[]>([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState<number>(10);
    // pagination
    const [allCheck, setAllCheck] = useState(false);
    const [result, setResult] = useState<string[]>([]);
    const [totalValue, setTotalValue] = useState<number>(0);
    const [limitValue, setLimitValue] = useState<number>(0);
    const pageCount = Math.ceil(totalValue / limitValue);
    const [prevButton, setPrevButton] = useState<boolean>(false);
    const [nextButton, setNextButton] = useState<boolean>(false);
    const [tableId, setTableId] = useState<string[]>([]);
    const [pageValue, setPageValue] = useState<number>();
    const [cardName, setCardName] = useState("");
    const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'ascending' | 'descending' } | null>(null);

    useEffect(() => {
        monthlyDate().then((data) => {}).catch((err) => {console.log(err)});

        getInvoicesList(limit, page, invoiceTag).then((data) => {
            if (data && Array.isArray(data.invoices)) {
                setInvoiceList(data.invoices);
                setTotalValue(data.total)
                setLimitValue(data.limit)
                setPageValue(data.page)
            }

        }).catch((err) => {console.log(err)});
    }, [invoiceTag, page, limit, count]);



    const [searchTerm, setSearchTerm] = useState('');
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredInvoices = invoiceList.filter((member: any) => {
        const fullName = member.user_name || '';
        const spacesName = member.spaces_name || '';
        const amount = member.amount || '';

        return (
            fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            spacesName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            amount.toLowerCase().includes(searchTerm.toLowerCase())
        );
    });


    useEffect(() => {
        if (pageCount > 1) {
            setPrevButton(true)
        }
        if (page === 1) {
            setPrevButton(false)
        }
        // next button
        if (pageCount > 1) {
            setNextButton(true)
        }
        if (pageCount === page) {
            setNextButton(false)
        }
    }, [pageCount, page])


    // pagination

    const nextPage = () => {
        setAllCheck(false)
        setResult([])
        setTableId([])
        setPage(page + 1)
        setNextButton(true)
    }

    const prevPage = () => {
        setAllCheck(false)
        setResult([])
        setTableId([])
        setPage(page - 1)
    }

    const sortInvoices = (key: string) => {
        let direction: 'ascending' | 'descending' = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }

        const sortedInvoices = [...filteredInvoices].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });

        setInvoiceList(sortedInvoices);
        setSortConfig({ key, direction });
    };


    return (
        <div id='billing'>
            <Layout>
                <div className='mainContent'>
                    {/* <div className="invoiceHeading">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb m-0 ms-2">
                                <li className="breadcrumb-item px-0"><Link to="/billing">Billing</Link></li>
                                <li className="breadcrumb-item active" aria-current="page">All Invoices</li>
                            </ol>
                        </nav>
                    </div> */}

                    <div className="memberBox">
                        <div className="topLine">
                            <div className='tableHeading'>
                                <h6>All Invoices</h6>
                            </div>
                            <div className='memberSearch'>
                                <div className='searchInput'>
                                    <input type="text" placeholder='Search billing' onChange={handleInputChange} className='form-control' />
                                    <FontAwesomeIcon icon={faSearch} />
                                </div>
                                <div className='filterDropdown'>
                                    <Dropdown>
                                        <Dropdown.Toggle>
                                            <button className='filterBtn'>{invoiceTag ? invoiceTag : 'Status'}  <img src={arrowDown} alt='filter' /></button>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setInvoiceTag("all")}>All</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setInvoiceTag("unpaid")}>Unpaid</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setInvoiceTag("paid")}>Paid</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setInvoiceTag("void")}>Void</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setInvoiceTag("pending")}>Upcoming</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <Link to="/create-invoice"><FontAwesomeIcon icon={faPlus} /> Create Manual Invoice</Link>
                            </div>
                        </div>
                        <div className="billingList">
                            <Table responsive hover>
                                <thead>
                                    <tr>
                                        <th><label className="tableCheckBox">
                                            <div className="contactCheck">
                                                <input type="checkbox" name="agreement" />
                                                <span className="checkmark"></span></div>
                                        </label></th>
                                        <th onClick={() => sortInvoices('invoice_id')}>
                                            ID{' '}
                                            {sortConfig?.key === 'invoice_id' && (
                                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUp : faArrowDown} />
                                            )}
                                        </th>
                                        <th onClick={() => sortInvoices('user_name')}>
                                            Member{' '}
                                            {sortConfig?.key === 'user_name' && (
                                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUp : faArrowDown} />
                                            )}
                                        </th>
                                        <th onClick={() => sortInvoices('created_at')}>
                                            Date Created{' '}
                                            {sortConfig?.key === 'created_at' && (
                                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUp : faArrowDown} />
                                            )}
                                        </th>
                                        <th onClick={() => sortInvoices('status')}>
                                            Status{' '}
                                            {sortConfig?.key === 'status' && (
                                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUp : faArrowDown} />
                                            )}
                                        </th>
                                        <th onClick={() => sortInvoices('total_amount')}>
                                            Amount{' '}
                                            {sortConfig?.key === 'total_amount' && (
                                                <FontAwesomeIcon icon={sortConfig.direction === 'ascending' ? faArrowUp : faArrowDown} />
                                            )}
                                        </th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredInvoices && filteredInvoices.map((invoice: any, i: number) => <tr key={`filtered` + i}>
                                        <td><label className="tableCheckBox">
                                            <div className="contactCheck">
                                                <input type="checkbox" name="agreement" />
                                                <span className="checkmark"></span></div>
                                        </label></td>
                                        <td><Link to={`/invoice-details/${invoice.id}`}>#INV{invoice.invoice_id}</Link></td>
                                        <td>
                                            {invoice.member_image ? <img src={`${API}/${invoice.member_image}`} width="32px" height="32px" alt="avatar" style={{ borderRadius: "50%" }} />
                                                : <img className='default' src={memberAvatar} width="32px" height="32px" alt="avatar" style={{ borderRadius: "50%" }} />
                                            }
                                            {invoice.user_name}
                                        </td>
                                        <td>{moment(invoice.created_at).format("MMMM DD, YYYY")}</td>
                                        {invoice.status ? <td className='status'>
                                            <span className={invoice.status === "unpaid" ? "unpaid" : "void"}>{invoice.status}</span>
                                        </td> : <>
                                            {invoice.renewal_frequency === "resource" ? <td className='status'>
                                                {parseFloat(invoice.total_payment_amount) >= parseFloat(invoice.amount) ? <span className='paid'>Paid</span> : <span className='unpaid'>Unpaid</span>}
                                            </td> : invoice.renewal_frequency === "today" ? <td className='status'><span className='unpaid'>Unpaid</span></td> :
                                                <td className='status'>
                                                    {invoice.total_amount === 0 || invoice.total_payment_amount === 0 ? (
                                                        <span className='draft'>Upcoming</span>
                                                    ) : (
                                                        parseFloat(invoice.total_payment_amount) >= parseFloat(invoice.total_amount) ? (
                                                            <span className='paid'>Paid</span>
                                                        ) : (
                                                            <span className='unpaid'>Unpaid</span>
                                                        )
                                                    )}
                                                </td>}
                                        </>}
                                        {invoice.renewal_frequency === "resource" ?
                                            <td>{invoice.amount ? <>${parseFloat(invoice.amount).toFixed(2)}</> : "N/A"}</td> :
                                            <td>{invoice.total_amount ? <>${(parseFloat(invoice.total_amount) - parseFloat(invoice.total_payment_amount)).toFixed(2)}</> : "N/A"}
                                            </td>}
                                        <td className='billingAction'>
                                            <button className='btn download px-2'><img src={more} alt="download" /></button>
                                        </td>
                                    </tr>)}

                                </tbody>
                            </Table>
                            <Pagination page={page} paginationTitle="Invoices" setPage={setPage} limit={limit} setLimit={setLimit} prevButton={prevButton} nextButton={nextButton} pageValue={pageValue} totalValue={totalValue} prevPage={prevPage} nextPage={nextPage} allRequestList={invoiceList} />

                        </div>
                    </div>
                </div>


            </Layout>
        </div>
    )
}

export default Billing