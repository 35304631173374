import axios from "axios";
import logoutUser from "./logoutUser";
import { getMiddleWord } from '../CommonFunction/Function';
import { showNotifications, TOAST_TYPE } from "../CommonFunction/toaster";

export const API_ROOT = process.env.REACT_APP_DOCOTEAM_API_URL;

const http = (ContentType, baseURL = API_ROOT) => {
  let accessToken = false;
  const { token } = JSON.parse(localStorage.getItem("company") || '{}');

  const urlParams = new URLSearchParams(window.location.search);
  const tokenFromUrl = urlParams.get('token');

  if(token) {
    accessToken = token;
  } else if(tokenFromUrl) {
    accessToken = tokenFromUrl;
  };

  const headers = {
    "Content-Type": ContentType || "application/json",
    Accept: 'application/json',
    Authorization: accessToken? `Bearer ${accessToken}` : "",
    "x-database-name": getMiddleWord(window.location.hostname) || '',
  };

  const client = axios.create({
    baseURL,
    headers,
  });

  // Intercept response object and handleSuccess and Error Object
  function handleSuccess(response) {
    return response;
  }

  /**   Intercept any unauthorized request.
   *    status 401 means either accessToken is expired or invalid
   *    dispatch logout action accordingly     * */
  function handleError(error) {
    if(error.response.data && typeof error.response.data.message === 'string') {
      showNotifications(TOAST_TYPE.error, error.response.data.message);
    } else {
      showNotifications(TOAST_TYPE.error, "Something went wrong.");
    }
    
    // Access token is expired or invalid
    if (error.response?.status === 401) {
      logoutUser();
    }

    if (error.response?.status === 500) {
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response.data);
  }

  client.interceptors.response.use(handleSuccess, handleError);

  function get(path) {
    return client.get(path).then((response) => response.data);
  }

  function post(path, payload) {
    return client.post(path, payload).then((response) => response.data);
  }

  function put(path, payload) {
    return client.put(path, payload).then((response) => response.data);
  }

  function patch(path, payload) {
    return client.patch(path, payload).then((response) => response.data);
  }

  function deleteApi(path, data) {
    if (data) {
      return client.delete(path, data).then((response) => response?.data);
    }
    return client.delete(path).then((response) => response.data);
  }

  return {
    get,
    post,
    put,
    patch,
    delete: deleteApi,
  };
};
export default http;