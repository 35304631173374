// chat mode
export const CHAT_FILE = 0
export const CHAT_TEXT = 1

// chat type
export const CHAT_GROUP = 1
export const CHAT_SINGLE = 0

// ICON

export const GROUP_ICON = "/group.svg"