import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import memberIcon from "../../Assets/Images/icon/member.png";
import { assignmentUpdate } from '../../api/assignments';
import { showNotifications, TOAST_TYPE } from '../../CommonFunction/toaster';


interface AddAssignmentProps {
    handleClose: () => void;
    show: boolean;
    setShow: (type: boolean) => void;
    assignInfo: any
}
const AssignmentPrice = ({ show, handleClose, setShow, assignInfo }: AddAssignmentProps) => {
    const [amount, setAmount] = useState(0);
    const [invoiceId, setInvoiceId] = useState("");
    const [assignId, setAssignId] = useState("");
  
    console.log('assignInfo',assignInfo);
    
    
    useEffect(() => {
        setAmount(parseInt(assignInfo.amount));
        setInvoiceId(assignInfo.invoice_id);
        setAssignId(assignInfo.id);
    }, [assignInfo]);

    const amountUpdate = () =>{
        let assignInfo={
            "id":assignId,
            "invoice_id":invoiceId,
            "assignment_amount":amount
        }
        assignmentUpdate(assignInfo).then((data) => {
           setShow(false)
           showNotifications(TOAST_TYPE.success,"Assignment update")
        })
    }

    return (
        <div>
            <Modal show={show} onHide={handleClose} centered size="sm" id="addResource">
                <div className="addMemberForm">
                    <button className='closeModal' onClick={handleClose}>
                        <FontAwesomeIcon icon={faXmark} />
                    </button>
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className='addMemberHeading'>
                                    <img src={memberIcon} alt="member" />
                                    <p>Assignment Price</p>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="assignmentPrices memberInput">
                                    <input value={amount} onChange={(e) => setAmount(parseInt(e.target.value))} type='number' className='form-control' placeholder='Price' />
                                    <button className='btn' onClick={amountUpdate}>Update</button>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </Modal>
        </div>
    )
}

export default AssignmentPrice